import {
  DocumentData,
  DocumentDataTableOfContents,
  RelatedDocumentData,
} from "../assets/interfaces/dto";
import { NavigateFunction } from "react-router-dom";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import { TiArrowBack } from "react-icons/ti";
import LoadingSpinner from "../components/LoadingSpinner";
import RelatedDocumentsSide from "../components/RelatedDocumentsSide";
import ErrorMessage from "../components/ErrorMessage";
import Footer from "../components/Footer";
import React from "react";

/**
 * Sort the related documents alphabetically on title
 * @param {RelatedDocumentData[]} documents: The request type name
 * @return {RelatedDocumentData[]}
 */
export function sortRelatedDocuments(
  documents: RelatedDocumentData[]
): RelatedDocumentData[] {
  return documents.sort(function (
    a: RelatedDocumentData,
    b: RelatedDocumentData
  ) {
    let titleA = a.title.toUpperCase();
    let titleB = b.title.toUpperCase();
    return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
  });
}
/**
 * Render function for a single document
 * @param {DocumentData| DocumentDataTableOfContents} data: Content of the page
 * @param {NavigateFunction} navigate: Navigation function to go back in the history
 * @param {boolean} isLoading: Is this page still fetching data
 * @param {string} errorMessage: Error message
 * @return JSX.Element
 */
export function renderSingleDocument(
  data: DocumentData | DocumentDataTableOfContents,
  navigate: NavigateFunction,
  isLoading: boolean,
  errorMessage: string
): JSX.Element {
  return (
    <>
      <Navbar />
      <Header title={data.title} />
      <section className="data__wrapper inner_nav__container">
        <span onClick={() => navigate(-1)}>
          <TiArrowBack />
          Go back
        </span>
      </section>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="data__wrapper single">
            <div
              className="data__wrapper_single"
              dangerouslySetInnerHTML={{ __html: data.html_output }}
            ></div>
            <RelatedDocumentsSide related_documents={data.related_documents} />
          </div>
          {errorMessage.length > 0 ? (
            <ErrorMessage message={errorMessage} />
          ) : (
            <></>
          )}
        </>
      )}
      <Footer />
    </>
  );
}
