import React from "react";
import { RelatedDocumentData } from "../assets/interfaces/dto";
import RelatedDocument from "./RelatedDocument";
import "./related-documents.css";

export default function RelatedDocumentsSide(props: {
  related_documents: RelatedDocumentData[];
}) {
  return (
    <>
      <div className="related-documents__side_container">
        {props.related_documents.length > 0 ? (
          <>
            <h3 id="quick_reference">Quick Reference</h3>
            <div className="related-documents__side_list">
              {props.related_documents.map((relatedDocument, index) => (
                <RelatedDocument key={index} data={relatedDocument} />
              ))}
            </div>
          </>
        ) : <></>}
      </div>
    </>
  );
}
