import React from "react";
import "./tabs.css";
import { NavLink } from "react-router-dom";
import { BsFillRocketTakeoffFill, BsFillArrowDownCircleFill, BsFillArrowUpCircleFill, BsFillGearFill } from "react-icons/bs";

export default function Tabs() {
  return (
    <div className="tabs__container">
      <NavLink to="quickstart">
      <div className="tabs__tab">
          <BsFillRocketTakeoffFill />
          Quick Start
      </div>
      </NavLink>
      <NavLink to="access">
      <div className="tabs__tab">
          <BsFillArrowDownCircleFill />
          Access Data
      </div>
      </NavLink>
      <NavLink to="store">
      <div className="tabs__tab">
          <BsFillArrowUpCircleFill />
          Store Data
      </div>
      </NavLink>
      <NavLink to="manage">
      <div className="tabs__tab">
          <BsFillGearFill />
          Manage Projects
      </div>
      </NavLink>
    </div>
  );
}
