import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import Button from "./Button";
import MDR from "../assets/images/MDR_logo_white_no_text.png";
import MUMC from "../assets/images/mumc_light.svg";
import UM from "../assets/images/UM-logo-white.png";

export default function Footer() {
  const MDRHomeUrl = window.env.REACT_APP_MDR_HOME_URL;
  const dataHubHomeUrl = window.env.REACT_APP_DATAHUB_HOME_URL;
  const privacyPolicyUrl = `${dataHubHomeUrl}/privacy-statement`;
  const termsOfServiceUrl = `${dataHubHomeUrl}/terms-of-service`;

  return (
    <div id="contact" className="footer__container">
      <div className="footer__content">
        <div className="footer__header">
          {window.location.href.includes("support") ? (
            <div
              className="footer__logo_container"
              style={{ marginBottom: "40px" }}
            >
              <img id="UM" src={UM} alt="UM" />
              <img id="MDR" src={MDR} alt="MDR" />
              <img id="MUMC" src={MUMC} alt="MUMC" />
            </div>
          ) : (
            <>
              <div className="footer__logo_container">
                <img id="UM" src={UM} alt="UM" />
                <img id="MDR" src={MDR} alt="MDR" />
                <img id="MUMC" src={MUMC} alt="MUMC" />
              </div>
              <h4>
                <Link to="/support">
                  <Button
                    className="primary"
                    text="Have a question, remark or request? Contact us"
                  />
                </Link>
              </h4>
            </>
          )}
        </div>

        <div className="footer__body flex">
          <div className="footer__body_item">
            <h5>Address</h5>
            <p>
              DataHub Maastricht <br />
              Paul-Henri Spaaklaan 1 (3rd floor) <br />
              6229 EN Maastricht <br />
              Nederland
            </p>
          </div>
          <div className="footer__body_item">
            <h5>Contact details</h5>
            <a href="tel:0031433872844">
              <p>0031 43 3872844</p>
            </a>
            <a href="mailto:datahub@maastrichtuniversity.nl">
              <p>datahub@maastrichtuniversity.nl</p>
            </a>
            <a href="mailto:datahub@mumc.nl">
              <p>datahub@mumc.nl</p>
            </a>
          </div>
          <div className="footer__body_item">
            <h5>Resources</h5>
            <a href={privacyPolicyUrl}>
              <p>Privacy policy</p>
            </a>
            <a href={termsOfServiceUrl }>
              <p>Terms of service</p>
            </a>
            <Link to="/">
              <p>Help center</p>
            </Link>
            <a href="https://mumc.atlassian.net/servicedesk/customer/user/requests?page=1&reporter=all&statuses=open">
              <p>Support requests</p>
            </a>
          </div>
          <div className="footer__body_item">
            <h5>Tools</h5>
            <a href={MDRHomeUrl}>
              <p>Maastricht Data Repository</p>
            </a>
            <a href="https://dmp.datahubmaastricht.nl">
              <p>DMP Maastricht</p>
            </a>
            <a href={dataHubHomeUrl}>
              <p>DataHub</p>
            </a>
          </div>
        </div>
      </div>

      <hr className="footer__border" />
      <div className="footer__footer">
        © 2023, made by DataHub <br />
        <a href="https://www.linkedin.com/company/datahubmaastricht/">
          <AiFillLinkedin className="linkedin" />
        </a>
        <a href="https://github.com/orgs/MaastrichtUniversity/">
          <AiFillGithub className="github" />
        </a>
      </div>
    </div>
  );
}
