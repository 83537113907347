import React from "react";
import { RelatedDocumentData } from "../assets/interfaces/dto";
import { Link } from "react-router-dom";
import { FiExternalLink } from 'react-icons/fi'
import "./related-document.css";

export default function RelatedDocument(props: { data: RelatedDocumentData }) {
  const reactRoute = props.data.link.replace("/help", "")

  return (
    <Link className="related-document" to={reactRoute}>
      <div>
        <div className="related-document-link">
          <FiExternalLink />
        </div>
        <div className="related-document-title">{props.data.title}</div>
        <div className="related-document-excerpt">{props.data.excerpt}</div>
      </div>
    </Link>
  );
}
