import React from "react";
import "./error-message.css";
import { GrCircleAlert } from "react-icons/gr";

const style = { fontSize: "30px", fill:"#ff8f77" }
export default function ErrorMessage(props: { message: string }) {
  return (
    <section className="data__wrapper error__container">
        <div className="error__icon">
          <GrCircleAlert style={style}/>
        </div>
      <h5 className="error__message">{props.message}</h5>
    </section>
  );
}
