import React from "react";
import { useState } from "react";
import { Transition } from "react-transition-group";
import "./navbar.css";
import { Link } from "react-router-dom";
import { IoIosApps } from "react-icons/io";
import { RiQuestionAnswerFill } from "react-icons/ri";
import {
  AiOutlineMenu,
  AiOutlineClose,
} from "react-icons/ai";
import Logo from "../assets/images/MDR_logo_white_no_text.png";
import { motion, useScroll } from "framer-motion";

export default function Navbar() {
  const LOGIN_URL = `${window.env.REACT_APP_MDR_URL}/saml2/login/`;
  const { scrollYProgress } = useScroll();

   /* State for transitioning between hidden/visible mobile dropdown */
   const [dropdownMobile, setDropdownMobile] = useState(false);
   const handleClick = () => {
     setDropdownMobile(!dropdownMobile);
   };

    /* Styling objects for react-transition library for smooth transition between states */

    const transitionStyles: { [id: string]: React.CSSProperties } = {
      entering: { opacity: 1, visibility: "visible" },
      entered: { opacity: 1, visibility: "visible" },
      exiting: { opacity: 0, visibility: "hidden" },
      exited: { opacity: 0, visibility: "hidden" },
    };

  return (
    <nav className="navbar">
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
      <div className="navbar__breadcrumbs">
        <Link to="/">
          <img src={Logo} alt="MDR Help center" />
        </Link>
      </div>
      {/* MOBILE NAVIGATION */}
      <div className="navbar__hamburger flex" onClick={handleClick}>
        {dropdownMobile ? <AiOutlineClose /> : <AiOutlineMenu />}
      </div>

      <Transition in={dropdownMobile} timeout={100}>
        {(state) => (
          <div
            className="navbar__dropdown_mobile"
            style={{
              ...transitionStyles[state],
            }}
          >
            <div className="navbar__internal_links">
              <Link to="/support">
                <RiQuestionAnswerFill />
                Contact us
              </Link>
              <a href={LOGIN_URL}>
                <IoIosApps />
                Go to MDR
              </a>
            </div>
          </div>
        )}
      </Transition>

      {/* DESKTOP NAVIGATION  */}
      <div className="navbar__login navbar__desktop">
        <Link to="/support">
          <RiQuestionAnswerFill />
          Contact us
        </Link>
        <a href={LOGIN_URL}>
          <IoIosApps />
          Go to MDR
        </a>
      </div>
    </nav>
  );
}
