import React, { useEffect, useState } from "react";
import { DocumentDataTableOfContents } from "../assets/interfaces/dto";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import TableOfContents from "./TableOfContents";
import "./getting-started.css";
import RelatedDocuments from "./RelatedDocuments";

export default function GettingStarted(props: { label: string }) {
  const [data, setData] = useState<DocumentDataTableOfContents>({
    title: "",
    html_output: "",
    related_documents: [],
    table_of_content: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const getApiData = (label: string, controller: AbortController) => {
    fetch(`/help_backend/confluence_documents/label/${label}`, {
      signal: controller.signal,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          throw new Error("Server Error");
        }
      })
      .then((result) => {
        setIsLoading(false);
        result.related_documents.sort(function (
          a: DocumentDataTableOfContents,
          b: DocumentDataTableOfContents
        ) {
          let titleA = a.title.toUpperCase();
          let titleB = b.title.toUpperCase();
          return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
        });
        setData(result);
      })
      .catch((err) => {
        if (!controller.signal.aborted) {
          setIsLoading(false);
          setErrorMessage(
            "Something went wrong, can't retrieve the document. Please try again later !  "
          );
          console.log(err);
        }
      });
  };

  useEffect(() => {
    const href = window.location.href;
    if (href.includes("#")) {
      const id = `${href.substring(href.indexOf("#") + 1)}`;
      const anchor = document.getElementById(id);
      if (anchor) {
        anchor.scrollIntoView({ behavior: "auto" });
      }
    }
  }, [data]);

  useEffect(() => {
    const controller = new AbortController();
    getApiData(props.label, controller);
    return () => {
      controller.abort();
      setData({
        title: "",
        html_output: "",
        related_documents: [],
        table_of_content: "",
      });
      setIsLoading(true);
      setErrorMessage("");
    };
  }, [props.label]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {errorMessage.length > 0 ? (
            <ErrorMessage message={errorMessage} />
          ) : (
            <>
              <div className="main">
                <div className="title__container">
                  <h3>{data.title}</h3>
                </div>
                <div className="data__wrapper data__wrapper_home">
                  <TableOfContents table_of_content={data.table_of_content} />
                  <div className="getting_started_data__container">
                    <div
                      dangerouslySetInnerHTML={{ __html: data.html_output }}
                    ></div>
                  </div>
                </div>
              </div>
              <RelatedDocuments related_documents={data.related_documents} />
            </>
          )}
        </>
      )}
    </>
  );
}
