import React from "react";
import { Code } from "react-content-loader";

const LoadingSpinner = () => (
  <section className="data__wrapper loader__container">
    <Code  style={{ width: '100%' }} />
  </section>
);

export default LoadingSpinner;
