import React from "react";
import { HashLink } from "react-router-hash-link";

export default function TableOfContents(props: { table_of_content: string }) {
  return (
    <div className="toc__container">
      <h4>In this article</h4>
      <div>
        <ul>
          <li>
            <HashLink smooth to="#top">
              To top
            </HashLink>
          </li>
        </ul>
      </div>
      <div dangerouslySetInnerHTML={{ __html: props.table_of_content }}></div>
      <div>
        <ul>
          <li>
            <HashLink to="#quick_reference">
              Quick Reference
            </HashLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
