import React from "react";
import "./header.css";

export default function Header(props: { title: string }) {
  return (
    <div className="header__container">
      <div className="header__titles">
        <h2 id="top">{props.title}</h2>
      </div>
    </div>
  );
}
