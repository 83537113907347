import React from "react";
import {RelatedDocumentData} from "../assets/interfaces/dto";
import RelatedDocument from "./RelatedDocument";
import "./related-documents.css"

export default function RelatedDocuments(props: { related_documents: RelatedDocumentData[] }) {
  return (
    <>
            <section className="data__wrapper related-documents__container">
              <h2 id="quick_reference">Quick Reference</h2>
              <div id="related-documents-list">
                {props.related_documents.map((relatedDocument, index) => (
                  <RelatedDocument key={index} data={relatedDocument} />
                ))}
              </div>
            </section>
    </>
  );
}

