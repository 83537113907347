import React from "react";
import "./button.css";

export default function Button(props: { text: string; className: string }) {
  return (
    <button className={`button ${props.className}`} type="button">
      <span>{props.text}</span>
    </button>
  );
}
