import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({children}: any) {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
  document.documentElement.scrollTo({
    top: 0,
    left: 0,
    behavior: "auto", 
  });
  }, [pathname]);

  return children;
}