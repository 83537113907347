import { useNavigate, useParams } from "react-router-dom";
import "./single-document.css";
import { DocumentData } from "../assets/interfaces/dto";
import { useState, useEffect } from "react";
import { renderSingleDocument, sortRelatedDocuments } from "./SingleDocument";

export default function SingleDocumentById(props: {}) {
  let { pageId } = useParams<{ pageId?: string }>();
  const navigate = useNavigate();
  const [data, setData] = useState<DocumentData>({
    title: "",
    html_output: "",
    related_documents: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isNaN(Number(pageId))) {
      setData({ title: "", html_output: "", related_documents: [] });
      setIsLoading(false);
      setErrorMessage("Something went wrong. Page id provided is not a number");
    } else {
      // Function to collect data
      const getApiData = () => {
        fetch(`/help_backend/confluence_documents/id/${pageId}`)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw new Error("Server Error");
            }
          })
          .then((result) => {
            setIsLoading(false);
            result.related_documents = sortRelatedDocuments(
              result.related_documents
            );
            setData(result);
          })
          .catch((err) => {
            setIsLoading(false);
            setErrorMessage("Something went wrong. Please try again later !");
          });
      };
      getApiData();
    }

    return () => {
      setData({ title: "", html_output: "", related_documents: [] });
      setIsLoading(true);
      setErrorMessage("");
    };
  }, [pageId]);

  return renderSingleDocument(data, navigate, isLoading, errorMessage);
}
