import SingleDocumentById from "./pages/SingleDocumentById";
import React from "react";
import "./app.scss";
import "./assets/fontawesome/css/all.css";
import AskSupport from "./pages/AskSupport";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout";
import GettingStarted from "./components/GettingStarted";
import SingleDocumentByLabel from "./pages/SingleDocumentByLabel";

function App() {
  return (
    <main>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/quickstart" />} />
          <Route
            path="quickstart"
            element={<GettingStarted label={"getting_started_general"} />}
          />
          <Route
            path="manage"
            element={<GettingStarted label={"getting_started_manage"} />}
          />
          <Route
            path="store"
            element={<GettingStarted label={"getting_started_contribute"} />}
          />
          <Route
            path="access"
            element={<GettingStarted label={"getting_started_view"} />}
          />
        </Route>
        <Route path="/support/" element={<AskSupport />} />
        <Route path="/support/:uuid" element={<AskSupport />} />
        <Route path="/page_id/:pageId" element={<SingleDocumentById />} />
        <Route path="/label/:pageLabel" element={<SingleDocumentByLabel />} />
        <Route path="*" element={<Navigate to="/quickstart" />} />
      </Routes>
    </main>
  );
}

export default App;
