import { useNavigate, useParams } from "react-router-dom";
import "./single-document.css";
import { DocumentDataTableOfContents } from "../assets/interfaces/dto";
import { useState, useEffect } from "react";
import { renderSingleDocument, sortRelatedDocuments } from "./SingleDocument";

export default function SingleDocumentByLabel(props: {}) {
  let { pageLabel } = useParams<{ pageLabel?: string }>();
  const navigate = useNavigate();
  const [data, setData] = useState<DocumentDataTableOfContents>({
    title: "",
    html_output: "",
    related_documents: [],
    table_of_content: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Function to collect data
    const getApiData = () => {
      fetch(`/help_backend/confluence_documents/label/${pageLabel}`)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw new Error("Server Error");
          }
        })
        .then((result) => {
          setIsLoading(false);
          result.related_documents = sortRelatedDocuments(
            result.related_documents
          );
          setData(result);
        })
        .catch((err) => {
          setIsLoading(false);
          setErrorMessage("Something went wrong. Please try again later !");
        });
    };

    getApiData();
    return () => {
      setData({
        title: "",
        html_output: "",
        related_documents: [],
        table_of_content: "",
      });
      setIsLoading(true);
      setErrorMessage("");
    };
  }, [pageLabel]);

  return renderSingleDocument(data, navigate, isLoading, errorMessage);
}
