import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Modal from "react-bootstrap/Modal";
import "./ask-support.css";
import { Link, useParams } from "react-router-dom";
import { BsCheckCircle, BsExclamationCircle } from "react-icons/bs";
import { BugDiv, ExternalAccessDiv, FeatureRequestDiv, RequestType, SupportRequestDiv } from "./AskSupportRequestType";
import LoadingSpinner from "../components/LoadingSpinner";
import ReCAPTCHA from "react-google-recaptcha";

const styleSuccess = { color: "green" };
const styleError = { color: "#ff8f77" };

/**
 * Wrapper function around fetch to execute 'submit request' API call
 * @param requestType: The request type name
 * @param data: The stringify JSON payload
 * @param setErrorMessage: The state dispatch function to update the error state
 */
function sendRequest(requestType: RequestType, data: string, setErrorMessage: React.Dispatch<React.SetStateAction<boolean>>) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: data,
  };
  fetch(`/help_backend/submit_request/${requestType}`, requestOptions)
    .then((response) => {
      if (response.ok) {
        setErrorMessage(false);
        return response.json();
      }
      return Promise.reject(response);
    })
    .catch(() => {
      setErrorMessage(true);
    });
}

/**
 * Ask DataHub support form page
 * @constructor
 */
export default function AskSupport() {
  const siteKey = window.env.REACT_APP_CAPTCHA_SITE_KEY;
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const submitButton = useRef<HTMLButtonElement>(null);

  /* Enable submit button if captcha is completed */
  const enableSubmitButton = () => {
    if (submitButton.current) {
      submitButton.current.disabled = false;
    }
  };
  const disableSubmitButton = () => {
    if (submitButton.current) {
      submitButton.current.disabled = true;
    }
  };

  let { uuid } = useParams<{ uuid?: string }>();

  const [selectedCategory, setSelectedCategory] = useState(RequestType.Question);
  const [showBugDiv, setBugDiv] = useState(false);
  const [showFeatureRequestDiv, setFeatureRequestDiv] = useState(false);
  const [showSupportRequestDiv, setSupportRequestDiv] = useState(false);
  const [showExternalAccessDiv, setExternalAccessDiv] = useState(false);
  const [showErrorMessage, setErrorMessage] = useState(false);
  const [showModal, setModal] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [browser, setBrowser] = useState("");
  const [os, setOs] = useState("");

  /**
   * Get the users browser by using userAgent and
   * set the Browser state
   */
  function handleBrowser() {
    const userAgent = navigator.userAgent;
    // Check for the browser
    if (userAgent.indexOf("Chrome") > -1) {
      setBrowser("chrome");
    } else if (userAgent.indexOf("Safari") > -1) {
      setBrowser("safari");
    } else if (userAgent.indexOf("Firefox") > -1) {
      setBrowser("firefox");
    } else {
      setBrowser("other");
    }
  }

  /**
   * Get the users operating system by using userAgent and
   * set the Operating System state
   */
  function handleOperatingSystem() {
    const userAgent = navigator.userAgent;
    // Check for the operating system
    if (userAgent.indexOf("Windows") > -1) {
      setOs("windows");
    } else if (userAgent.indexOf("Mac") > -1) {
      setOs("mac");
    } else if (userAgent.indexOf("Ubuntu") > -1) {
      setOs("ubuntu");
    } else {
      setOs("other");
    }
  }

  /**
   * Fetch the user email address from the url parameter uuid.
   * If valid, autofill the email address with the fetched value and
   * autofill user operating system and browser fields.
   * Then, set 'request support' as the default category
   *
   */
  useEffect(() => {
    const getApiData = (userId: string | undefined) => {
      if (userId !== undefined && parseInt(userId)) {
        fetch(`/help_backend/submit_request/irods_uuid?uuid=${uuid}`)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              throw new Error("Server Error");
            }
          })
          .then((result) => {
            setEmailValue(result);
            setIsLoading(false);
            setSupportRequestDiv(true);
            handleBrowser();
            handleOperatingSystem();
            setSelectedCategory(RequestType.Support);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    };
    getApiData(uuid);
    return () => {
      setEmailValue("");
      setIsLoading(true);
    };
  }, [uuid]);

  const handleModalClose = () => {
    setModal(false);
    // reset error message
    setErrorMessage(false);
  };

  /**
   * Create the email input field component
   * @constructor
   */
  const EmailField = () => (
    <>
      <label>
        Email <span>*</span>
        <br />
        <input name="email" type={"email"} defaultValue={emailValue} required />
      </label>
    </>
  );

  /**
   * Create the description textarea component
   * @constructor
   */
  const DescriptionField = () => (
    <>
      <label>
        Description <span>*</span>
        <br />
        <textarea name="description" placeholder="Message" rows={8} cols={80} required defaultValue={descriptionValue}></textarea>
      </label>
    </>
  );

  /**
   * Create the category dropdown field component
   * @constructor
   */
  const CategoryField = () => (
    <>
      <label>
        Category <span>*</span>
        <br />
        <select name="category" value={selectedCategory} onChange={(e) => handleSelectedCategoryChange(e.target.value)} required>
          <option value={RequestType.Question}>General</option>
          <option value={RequestType.Bug}>Bug</option>
          {/* <option value={RequestType.Feature}>Feature request</option> */}
          <option value={RequestType.Support}>Support request</option>
          <option value={RequestType.ExternalAccess}>Organization access request</option>
        </select>
      </label>
    </>
  );

  /**
   * Helper function to clear form data on modal
   * close if the submission was successful
   */
  const supportFormReference = useRef<HTMLFormElement>(null);
  const clearForm = () => {
    if (supportFormReference.current != null) {
      supportFormReference.current.reset();
    }
    handleModalClose();
  };

  /**
   * Hide and show the different form components based on the request category selected
   * @param value: request type name
   */
  function handleSelectedCategoryChange(value: string) {
    switch (value) {
      case RequestType.Question:
        setBugDiv(false);
        setFeatureRequestDiv(false);
        setSupportRequestDiv(false);
        setExternalAccessDiv(false);
        setDescriptionValue("");
        setSelectedCategory(RequestType.Question);
        break;
      case RequestType.Bug:
        setBugDiv(true);
        setFeatureRequestDiv(false);
        setSupportRequestDiv(false);
        setExternalAccessDiv(false);
        setDescriptionValue("");
        setSelectedCategory(RequestType.Bug);
        break;
      // case RequestType.Feature:
      //   setBugDiv(false);
      //   setFeatureRequestDiv(true);
      //   setSupportRequestDiv(false);
      //   setExternalAccessDiv(false);
      //   setDescriptionValue("");
      //   setSelectedCategory(RequestType.Feature);
      //   break;
      case RequestType.Support:
        setBugDiv(false);
        setFeatureRequestDiv(false);
        setSupportRequestDiv(true);
        setExternalAccessDiv(false);
        setDescriptionValue("");
        setSelectedCategory(RequestType.Support);
        break;
      case RequestType.ExternalAccess:
        setBugDiv(false);
        setFeatureRequestDiv(false);
        setSupportRequestDiv(false);
        setExternalAccessDiv(true);
        setDescriptionValue("I would like to request access to Maastricht Data Repository for the organization described below.");
        setSelectedCategory(RequestType.ExternalAccess);
        break;
    }
  }

  /**
   * Submit event handler. Parse the user form data and post a 'submit_request' API call
   * @param event
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const recaptchaValue = recaptchaRef?.current?.getValue();
    const target = event.target as typeof event.target & {
      email: { value: string };
      category: { value: string };
      description: { value: string };
      bugDateTime: { value: string };
      bugMessage: { value: string };
      operatingSystem: { value: string };
      browser: { value: string };
      featureProblem: { value: string };
      featureFrequency: { value: string };
      featureRole: { value: string };
      organisationName: { value: string };
      organisationIP: { value: string };
      externalAccessEndDate: { value: string };
    };
    let category: string = target.category.value;
    switch (category) {
      case RequestType.Question:
        const requestQuestionData = JSON.stringify({
          token: recaptchaValue,
          email: target.email.value,
          description: target.description.value,
        });
        sendRequest(RequestType.Question, requestQuestionData, setErrorMessage);
        break;
      case RequestType.Bug:
        const requestBugData = JSON.stringify({
          token: recaptchaValue,
          email: target.email.value,
          description: target.description.value,
          error_timestamp: target.bugDateTime.value,
          error_message: target.bugMessage.value,
          operating_system: target.operatingSystem.value,
          browser: target.browser.value,
        });
        sendRequest(RequestType.Bug, requestBugData, setErrorMessage);
        break;
      // case RequestType.Feature:
      //   const requestFeatureData = JSON.stringify({
      //     token: recaptchaValue,
      //     email: target.email.value,
      //     description: target.description.value,
      //     problem: target.featureProblem.value,
      //     frequency: target.featureFrequency.value,
      //     role: target.featureRole.value,
      //   });
      //   sendRequest(RequestType.Feature, requestFeatureData, setErrorMessage);
      //   break;
      case RequestType.Support:
        const requestSupportData = JSON.stringify({
          token: recaptchaValue,
          email: target.email.value,
          description: target.description.value,
          operating_system: target.operatingSystem.value,
          browser: target.browser.value,
        });
        sendRequest(RequestType.Support, requestSupportData, setErrorMessage);
        break;
      case RequestType.ExternalAccess:
        const requestExternalAccessData = JSON.stringify({
          token: recaptchaValue,
          email: target.email.value,
          description: target.description.value,
          organisation_name: target.organisationName.value,
          organisation_ip: target.organisationIP.value,
          external_access_end_date: target.externalAccessEndDate.value,
        });
        sendRequest(RequestType.ExternalAccess, requestExternalAccessData, setErrorMessage);
        break;
    }
    setModal(true);
    recaptchaRef?.current?.reset();
    disableSubmitButton();
  };

  return (
    <>
      <Navbar />
      <Header title="Ask DataHub support" />
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <section className="data__wrapper">
              <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    {showErrorMessage ? (
                      <div className="modal_title">
                        <BsExclamationCircle style={styleError} className={"modal-title-icon"} />
                        Form submission failed
                      </div>
                    ) : (
                      <div className="modal_title">
                        <BsCheckCircle style={styleSuccess} className={"modal-title-icon"} />
                        Form submitted successfully
                      </div>
                    )}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {showErrorMessage ? (
                    <p>
                      Unfortunately your support request could not be submitted to DataHub support. Please try again later. If the problem still
                      persists please contact us via email at <br></br>
                      <a href="mailto:datahub@maastrichtuniversity.nl">datahub@maastrichtuniversity.nl</a>
                    </p>
                  ) : (
                    <p>
                      Thank you for contacting DataHub support. A member of our support team will pickup your request very soon. You should receive an
                      email shortly with a link to inform you about the progress of your support request.
                    </p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  {showErrorMessage ? (
                    <button className="modal__button" onClick={handleModalClose}>
                      Close
                    </button>
                  ) : (
                    <button className="modal__button" onClick={clearForm}>
                      Submit another
                    </button>
                  )}
                  <Link to="/">
                    <button className="modal__button secondary">Go Home</button>
                  </Link>
                </Modal.Footer>
              </Modal>
              <div className="form__container">
                <h2>Having trouble?</h2>
                <p>Choose between general, bug, feature request, support request or organization access request categories.</p>
                <form ref={supportFormReference} onSubmit={(e) => handleSubmit(e)}>
                  <EmailField />
                  <br />
                  <CategoryField />
                  <br />
                  <DescriptionField />
                  {showBugDiv ? <BugDiv userBrowser={browser} userOS={os} /> : null}
                  {showFeatureRequestDiv ? <FeatureRequestDiv /> : null}
                  {showSupportRequestDiv ? <SupportRequestDiv userBrowser={browser} userOS={os} /> : null}
                  {showExternalAccessDiv ? <ExternalAccessDiv /> : null}
                  <br />
                  <ReCAPTCHA
                    sitekey={siteKey}
                    ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
                    onChange={enableSubmitButton}
                    onExpired={disableSubmitButton}
                    onErrored={disableSubmitButton}
                  />
                  <button id="submit-form" ref={submitButton} className="submit__button" disabled>
                    Submit Form
                  </button>
                </form>
              </div>
            </section>
            <Footer />
          </>
        )}
      </>
    </>
  );
}
