import { Link } from "react-router-dom";
import React from "react";

export const enum RequestType {
  Question = "question",
  Bug = "bug",
  // Feature = "feature",
  Support = "support",
  ExternalAccess = "external_access",
}

/**
 * Create the component to show for bug request
 * @constructor
 */
export const BugDiv = (props: { userBrowser: string; userOS: string }) => {
  return (
    <>
      <hr />
      <h2>Tell us more ...</h2>
      <div className="bug__container">
        <div className="bug__container_small">
          <label>
            When did this happen?
            <br />
            <input name="bugDateTime" type={"datetime-local"} />
          </label>
          <br />
          <label>
            Error message given in MDR:
            <br />
            <textarea name="bugMessage" placeholder="Message" rows={4} cols={30} />
          </label>
        </div>
        <div className="bug__container_small">
          <Browser userBrowser={props.userBrowser} />
          <OperatingSystem userOS={props.userOS} />
        </div>
      </div>
    </>
  );
};

/**
 * Create the component to show for feature request
 * @constructor
 */
export const FeatureRequestDiv = () => (
  <>
    <hr />
    <h2>Tell us more ...</h2>
    <label>
      What problem are you trying to solve?
      <br />
      <textarea name="featureProblem" rows={4} cols={30} placeholder="Message" />
    </label>
    <br />
    <label>
      How often do you think you will use the feature?
      <br />
      <input name="featureFrequency" />
    </label>
    <br />
    <label>
      Your main role in MDR:
      <br />
      <select name="featureRole">
        <option value="">-- Select an option --</option>
        <option value="dataSteward">Data steward</option>
        <option value="manager">Manager</option>
        <option value="contributor">Contributor</option>
        <option value="viewer">Viewer</option>
      </select>
    </label>
    <br />
  </>
);

/**
 * Create the component to show for support request
 * @constructor
 */
export const SupportRequestDiv = (props: { userBrowser: string; userOS: string }) => {
  return (
    <>
      <hr />
      <h2>Tell us more ...</h2>
      <Browser userBrowser={props.userBrowser} />
      <OperatingSystem userOS={props.userOS} />
    </>
  );
};

/**
 * Create the component to show for the external access request
 * @constructor
 */
export const ExternalAccessDiv = () => (
  <>
    <hr />
    <h2>Tell us more ...</h2>
    <label>
      Organisation name
      <br />
      <input name="organisationName" />
    </label>
    <br />
    <label>
      Organisation public IP-range
      <br />
      <input name="organisationIP" />
    </label>
    <br />
    <label>
      End date for external access <span>*</span>
      <br />
      <input name="externalAccessEndDate" type={"date"} required />
    </label>
    <br />
    <p>
      For more information have a look at the how to: <Link to={"/label/how_to_external_access"}>Request access for an external organization</Link>
    </p>
  </>
);

/**
 * Create the dropdown component to select a browser
 * @constructor
 */
const Browser = (props: { userBrowser: string }) => {
  return (
    <label>
      Browser:
      <br />
      <select name="browser" defaultValue={props.userBrowser}>
        <option value="">-- Select an option --</option>
        <option value="firefox">Firefox</option>
        <option value="chrome">Chrome</option>
        <option value="safari">Safari</option>
        <option value="other">Other</option>
      </select>
    </label>
  );
};

/**
 * Create the dropdown component to select a browser
 * @constructor
 */
const OperatingSystem = (props: { userOS: string }) => {
  return (
    <>
      <br />
      <label>
        Operating System:
        <br />
        <select name="operatingSystem" defaultValue={props.userOS}>
          <option value="">-- Select an option --</option>
          <option value="windows">Windows</option>
          <option value="mac">Mac OS</option>
          <option value="ubuntu">Ubuntu</option>
          <option value="other">Other</option>
        </select>
      </label>
    </>
  );
};
